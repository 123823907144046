<template>
  <div class="chart-selector">
    <div class="chart-selector-content">
      <div
        class="chart-selector-content-item"
        v-for="(item, index) of dataArray"
        :key="index"
        @click="onItemClick(index)"
      >
        <img
          class="chart-selector-content-item-icon"
          :src="item.isChecked ? checkedIcon : unCheckedIcon"
        />
        <p class="chart-selector-content-item-title">{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chartsDisplayInfoArray: Array,
  },

  data() {
    return {
      dataArray: [],
      checkedIcon: require("@imgs/report-check-blue.png"),
      unCheckedIcon: require("@imgs/report-check-white.png"),
    };
  },

  created() {
    this.dataArray = this.chartsDisplayInfoArray;
  },

  watch:{
    chartsDisplayInfoArray(value){
      this.dataArray = value
    }
  },

  methods: {
    // action
    onItemClick(index) {
      this.dataArray[index].isChecked = !this.dataArray[index].isChecked;
      this.processDataArrayBrakInfo();
      this.$emit("onSelectedCharts", this.dataArray);
    },

    // method
    processDataArrayBrakInfo() {
      //清空needBreak信息
      this.dataArray.forEach((item) => {
        item.needBreak = [];
      });
      //获取needBreak的条目
      const selectedArray = this.dataArray.filter((item) => {
        return item.isChecked == true;
      });
      let breakArray = [];
      //选中0、1、2条无需添加换行符
      if (selectedArray.length < 3) {
        breakArray = [];
      } else {
        breakArray = selectedArray.filter((item, index) => {
          return index % 2 == 1;
        });
      }
      //重置needBreak信息
      //totalPage
      let totalPage = Math.ceil(selectedArray.length / 2) + 2;
      breakArray.forEach((breakItem, index) => {
        const breakIndex = this.dataArray.findIndex((item) => {
          return item.name == breakItem.name;
        });
        //currentPage
        const currentPage = 3 + index;
        this.dataArray[breakIndex].needBreak = [currentPage, totalPage];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-selector {
  margin-top: 20px;

  &-content {
    display: flex;
    align-content: center;

    &-item {
      display: flex;
      align-items: center;
      margin-right: 20px;

      &-icon {
        width: 20px;
        height: 20px;
      }

      &-title {
        color: #666666;
        font-size: 14px;
        line-height: 20px;
        margin-left: 10px;
      }
    }
  }
}
</style>


