<template>
  <div v-if="reportModel" class="single-report">
    <div class="single-report-container">
      <!-- 第一页 -->
      <print-page :printHeaderInfo="printHeaderInfo" :currentPage="1" :totalPage="printInfo.totalPage">
        <div>
          <div class="single-report-container-top">
            <div class="single-report-container-top-top">
              <p class="single-report-container-top-top-title">
                使用报告（1天）
              </p>
              <barcode v-if="reportModel.patientNum" :patientNum="reportModel.patientNum" />
            </div>
            <div class="single-report-container-top-bottom">
              <title-info-label title="设备序列号" :info="reportModel.deviceSN" />
              <title-info-label title="统计时间" :info="reportModel.deviceStatisticsDuration" />
            </div>
          </div>
          <report-info-block title="患者资料" :dataArray="reportModel.basicInfoArray"
            :widthPercent="100"></report-info-block>
          <report-info-block title="事件统计" :dataArray="reportModel.eventStatisticsArray"
            :widthPercent="100"></report-info-block>
        </div>
      </print-page>
      <!-- 第二页 -->
      <print-page :printHeaderInfo="printHeaderInfo" :currentPage="2" :totalPage="printInfo.totalPage">
        <report-info-block title="设置信息" :dataArray="reportModel.settingInfoArray"
          :widthPercent="100"></report-info-block>
        <report-info-block title="治疗数据" :dataArray="reportModel.cureDataArray" :widthPercent="100"></report-info-block>
        <chart-selector class="no-print" :chartsDisplayInfoArray="chartsDisplayInfoArray"
          @onSelectedCharts="onSelectedCharts" />
      </print-page>
      <!-- 动态图表页 -->
      <div v-if="printInfo.displayChartCount">
        <print-header :info="printHeaderInfo" />
        <single-charts class="report-container-charts" :reportModel="reportModel"
          :chartsDisplayInfoArray="chartsDisplayInfoArray" :printHeaderInfo="printHeaderInfo">
        </single-charts>
        <print-footer v-if="printInfo.isLastPage" :firstPage="printInfo.totalPage" :totalPage="printInfo.totalPage"
          :marginTop="printInfo.lastPrintFooterMarginTop" />
      </div>
    </div>
  </div>
</template>

<script>
import TitleInfoLabel from "@c/report/title-info-label.vue";
import ReportInfoBlock from "@c/report/report-info-block.vue";
import PrintHeader from "@c/report/print/print-header.vue";
import PrintFooter from "@c/report/print/print-footer.vue";
import PrintPage from "@c/common/print-page.vue";
import SingleCharts from "@c/report/charts/single-charts.vue";
import Barcode from "@c/report/barcode.vue";
import ChartSelector from "@c/report/chart-selector.vue";
import { ReportModel } from "@js/model/report-model.js";
export default {
  components: {
    TitleInfoLabel,
    ReportInfoBlock,
    PrintHeader,
    PrintFooter,
    PrintPage,
    SingleCharts,
    Barcode,
    ChartSelector,
  },

  props: {
    selectedInfo: Object,
  },

  data() {
    return {
      reportModel: null,
      chartsDisplayInfoArray: [
        {
          name: "压力",
          isChecked: true,
          needBreak: [],
        },
        {
          name: "流量",
          isChecked: true,
          needBreak: [3, 4],
        },
        {
          name: "事件",
          isChecked: true,
          needBreak: [],
        },
        {
          name: "漏气",
          isChecked: false,
          needBreak: [],
        },
        {
          name: "潮气量",
          isChecked: false,
          needBreak: [],
        },
        {
          name: "呼吸率",
          isChecked: false,
          needBreak: [],
        },
        {
          name: "分钟通气量",
          isChecked: false,
          needBreak: [],
        },
        {
          name: "血氧",
          isChecked: false,
          needBreak: [],
        },
        {
          name: "心率",
          isChecked: false,
          needBreak: [],
        },
      ],
    };
  },

  computed: {
    printInfo() {
      //displayChartCount
      const displayChartCount = this.chartsDisplayInfoArray.filter((item) => {
        return item.isChecked == true;
      }).length;
      //totalPage
      const totalPage = Math.ceil(displayChartCount / 2) + 2;
      //isLastPage
      const isLastPage =
        displayChartCount == 2 ? true : displayChartCount % 2 == 1;
      //lastPrintFooterMarginTop
      const lastPrintFooterMarginTop = displayChartCount == 2 ? 460 : 800;
      return {
        displayChartCount,
        totalPage,
        isLastPage,
        lastPrintFooterMarginTop,
      };
    },

    //依赖reportModel
    printHeaderInfo() {
      return {
        patientName: this.reportModel?.model.user.name,
        deviceModel: this.reportModel?.deviceModel,
        deviceSN: this.reportModel?.deviceSN,
        statisticsTime: this.reportModel?.deviceStatisticsDuration,
      };
    },
  },

  async created() {
    this.reportModel = await this.getReportDetail();
  },

  watch: {
    async selectedInfo() {
      this.reportModel = await this.getReportDetail();
    },
  },

  methods: {
    // action
    onSelectedCharts(chartsDisplayInfoArray) {
      this.chartsDisplayInfoArray = chartsDisplayInfoArray;
    },

    // method
    async getReportDetail() {
      try {
        const params = {
          id: this.selectedInfo.singleBreathReportId,
        };
        const data = await this.$api.getSingleReportDetail(params);
        return new ReportModel(data);
      } catch (error) {
        this.$emit("onNoReport");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .m-header {
    display: block !important;
  }

  .m-footer {
    display: block !important;
  }

  .single-report-container {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .no-print {
    display: none !important;
  }
}

.single-report {
  width: 100%;

  &-container {
    width: 900px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 100px;
    padding-bottom: 100px;

    &-top {
      background-color: #f7f7f7;
      box-sizing: border-box;
      padding-top: 33px;
      padding-bottom: 20px;
      width: 100%;
      height: 171px;

      &-top {
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 10px;
        height: 76px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
          color: black;
          font-size: 48px;
          font-weight: bold;
          line-height: 33px;
        }
      }

      &-bottom {
        margin-top: 21px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &-selector {
      background-color: pink;
      width: 100%;
      height: 40px;
    }

    &-charts {
      width: 100%;
    }
  }
}
</style>