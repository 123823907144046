<template>
  <div class="charts">
    <div v-for="(item, index) of chartModel?.lineChartsArray" :key="item.title">
      <line-chart-pressure v-if="index == 0" :reportModel="reportModel"
        :lineChartPressureArray="chartModel.lineChartPressureArray" :timeBarArray="timeBarArray" :model="item"
        :chartWidth="chartWidth" :timeSelectBarWidth="timeSelectBarWidth" :isFullFunctional="isFullFunctional"
        :buttonArray="buttonArray" :inputSelectedIndex="selectedIndex"
        :inputExtremeTimeStampArray="extremeTimeStampArray" :chartsDisplayInfoArray="chartsDisplayInfoArray"
        :printHeaderInfo="printHeaderInfo" @onTimeSpanPicked="onTimeSpanPicked"
        @onIntervalSelected="onIntervalSelected"></line-chart-pressure>
      <line-chart v-else :timeBarArray="timeBarArray" :model="item" :chartWidth="chartWidth"
        :timeSelectBarWidth="timeSelectBarWidth" :isFullFunctional="isFullFunctional" :buttonArray="buttonArray"
        :inputSelectedIndex="selectedIndex" :inputExtremeTimeStampArray="extremeTimeStampArray"
        :chartsDisplayInfoArray="chartsDisplayInfoArray" :printHeaderInfo="printHeaderInfo"
        @onTimeSpanPicked="onTimeSpanPicked" @onIntervalSelected="onIntervalSelected"></line-chart>
      <bar-chart-event v-if="index == 1" :timeBarArray="timeBarArray" :barChartDataArray="chartModel.barChartEventArray"
        :chartWidth="chartWidth" :timeSelectBarWidth="timeSelectBarWidth" :isFullFunctional="isFullFunctional"
        :buttonArray="buttonArray" :inputSelectedIndex="selectedIndex"
        :inputExtremeTimeStampArray="extremeTimeStampArray" :chartsDisplayInfoArray="chartsDisplayInfoArray"
        :printHeaderInfo="printHeaderInfo" @onTimeSpanPicked="onTimeSpanPicked"
        @onIntervalSelected="onIntervalSelected" />
    </div>
  </div>
</template>

<script>
import LineChartPressure from "@c/report/charts/chart/single-chart/line-chart-pressure/line-chart-pressure.vue";
import LineChart from "@c/report/charts/chart/single-chart/line-chart.vue";
import BarChartEvent from "@c/report/charts/chart/single-chart/bar-chart-event.vue";
import { ChartModel } from "../../../assets/js/model/chart-model.js";
import { DateTool } from "@js/date-tool.js";

export default {
  components: {
    LineChartPressure,
    LineChart,
    BarChartEvent,
  },

  props: {
    chartWidth: Number,
    timeSelectBarWidth: Number,
    reportModel: Object,
    isFullFunctional: {
      type: Boolean,
      default: true
    },
    chartsDisplayInfoArray: {
      type: Array,
      default: [],
    },
    printHeaderInfo: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      buttonArray: [
        { title: "30s", value: 30000 },
        { title: "1m", value: 60000 },
        { title: "2m", value: 120000 },
        { title: "4m", value: 240000 },
        { title: "8m", value: 480000 },
        { title: "30m", value: 1800000 },
        { title: "1h", value: 3600000 },
        { title: "2h", value: 7200000 },
        { title: "4h", value: 14400000 },
        { title: "8h", value: 28800000 },
        { title: "All", value: undefined },
      ],
      chartModel: undefined,
      timeBarArray: [],
      timeBarInterval: 0, //时间轴单位毫秒数
      selectedIndex: 10,
      extremeTimeStampArray: [],
    };
  },

  created() {
    this.extremeTimeStampArray = [
      this.reportModel.initialStartTime,
      this.reportModel.initialEndTime,
    ];
    this.getChartData();
  },

  watch: {
    reportModel() {
      this.selectedIndex = 10;
      this.timeBarArray = [];
      this.extremeTimeStampArray = [
        this.reportModel.initialStartTime,
        this.reportModel.initialEndTime,
      ];
      this.getChartData();
    },

    chartsDisplayInfoArray: {
      handler(value) {
        // needGetBloodOxygenArray
        const bloodOxygenArray = this.chartModel.model.o2Arr
        const showBloodOxygen = value[7].isChecked
        const needGetBloodOxygenArray = !bloodOxygenArray?.length && showBloodOxygen
        // needGetHeartRateArray
        const showHeartRate = value[8].isChecked
        const heartRateArray = this.chartModel.model.prArr
        const needGetHeartRateArray = !heartRateArray?.length && showHeartRate
        // smartGet
        if (needGetBloodOxygenArray || needGetHeartRateArray) {
          this.getChartData()
        }
      },
      deep: true,
    }
  },

  methods: {
    onTimeSpanPicked(index) {
      this.selectedIndex = index;
      const timeInterval = this.buttonArray[index].value;
      const minTimeStamp = this.timeBarArray[0].timeStamp;
      const maxTimeStamp =
        this.timeBarArray[this.timeBarArray.length - 1].timeStamp;
      if (timeInterval) {
        //start
        let start = this.extremeTimeStampArray[0];
        //end
        const startIndex = this.timeBarArray
          .map((item) => {
            return item.timeStamp;
          })
          .indexOf(start);
        const endIndex = Math.round(timeInterval / this.timeBarInterval);
        let end = this.timeBarArray[startIndex + endIndex]?.timeStamp; //有可能超
        this.extremeTimeStampArray = [start, end ?? maxTimeStamp];
      } else {
        let start = minTimeStamp;
        let end = maxTimeStamp;
        this.extremeTimeStampArray = [start, end];
      }
      this.getChartData();
    },

    onIntervalSelected(intervalArray) {
      this.extremeTimeStampArray = intervalArray;
      this.getChartData();
    },

    //method
    async getChartData() {
      try {
        const params = {
          reportId: this.reportModel.reportID,
          start: this.extremeTimeStampArray[0],
          end: this.extremeTimeStampArray[1],
          showBloodOxygen: this.chartsDisplayInfoArray[7].isChecked,
          showHeartRate: this.chartsDisplayInfoArray[8].isChecked
        };
        const data = await this.$api.getSingleReportChartData(params);
        if (data) {
          this.chartModel = new ChartModel(data);
        } else {
          throw "暂无图表数据"
        }
        //初始数据
        if (!this.timeBarArray.length) {
          //时间跨度(毫秒)
          this.timeBarInterval = this.chartModel.timeBarInterval;
          //时间轴数据
          this.timeBarArray = this.chartModel.timeArray.map((item) => {
            return {
              timeStamp: item,
              timeStr: DateTool.milliTimestampToStr(item, "HH:mm:ss"),
            };
          });
          //时间轴端点
          this.extremeTimeStampArray = [
            this.timeBarArray[0].timeStamp,
            this.timeBarArray[this.timeBarArray.length - 1].timeStamp,
          ];
        }
      } catch (error) {
        this.$toast.showRed(error);
        this.chartModel = undefined
      }
    },
  },

};
</script>

<style lang="scss" scoped>
.title {
  font-size: 50px;
}
</style>